import { useDispatch } from 'react-redux'
import { OpenEgg, openEggsAsync, testWebWorker } from '../../petsSlice'

function PetEgg({ amount }) {
  const dispatch = useDispatch()
  const handleClick = () => {
    // dispatch(testWebWorker({ tier: 1, amount }))
    // dispatch({ type: 'test', payload: null })
    dispatch(OpenEgg({ tier: 1, amount }))
    dispatch(testWebWorker())
    // dispatch(openEggsAsync({ tier: 1, amount }))
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <button
        className="mx-auto px-6 py-2 border text-slate-900 font-bold border-blue-300 bg-blue-400 hover:bg-blue-300 focus-within:bg-blue-300 focus-within:ring focus-within:ring-slate-50 rounded-md m-2 transition-all duration-300 ease-in-out"
        onClick={handleClick}
      >
        Open {Intl.NumberFormat().format(amount)}x
      </button>
    </div>
  )
}

export default PetEgg
