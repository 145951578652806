import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import petsReducer from '../features/pets/petsSlice'
// import WorkerMiddleware from './workerMiddleware'

import createWorkerMiddleware from 'redux-worker-middleware'

const PetWorker = new Worker(new URL('./workers/pets/index.js', import.meta.url))
const WorkerMiddleware = createWorkerMiddleware(PetWorker)

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    pets: petsReducer
  },
  middleware: curryGetDefaultMiddleware => curryGetDefaultMiddleware().concat(WorkerMiddleware)
})
