const { RadiantPetEgg, PetList } = require('./petConstants')

const SortByRarity = (b, a) => {
  // Sort by rarity first
  // If it's a tie then sort by name

  if (a.rarity === 'Legendary') {
    return -1
  } else if (b.rarity === 'Legendary') {
    return 1
  } else if (a.rarity === 'Epic') {
    return -1
  } else if (b.rarity === 'Epic') {
    return 1
  } else if (a.rarity === 'Rare') {
    return -1
  } else if (b.rarity === 'Rare') {
    return 1
  } else if (a.rarity === 'Fine') {
    return -1
  } else if (b.rarity === 'Fine') {
    return 1
  } else if (a.rarity === 'Normal') {
    return -1
  } else if (b.rarity === 'Normal') {
    return 1
  }
}

async function GetRandomPet(PetEgg) {
  const rng = +(Math.random() * 99.999).toFixed(4)
  const index = PetEgg.findIndex(pet => pet.sum > rng)

  return index
}

async function OpenRadiantPetEgg(amount) {
  // let petList = Array(amount)
  //   .fill(0)
  //   .map(i => +(Math.random() * 99.999).toFixed(4))
  //   .map(rng => RadiantPetEgg.find(pet => pet.sum > rng))
  //   .map(pet => pet.name)

  // console.log(petList)

  // console.time('OpenRadiantPetEgg')
  // let petList = []
  // for (let i = 0; i < amount; i++) {
  //   const rng = +(Math.random() * 99.999).toFixed(4)
  //   const pet = RadiantPetEgg.find(pet => pet.sum > rng)
  //   petList.push(pet.name)
  // }
  let petList = [...PetList].map(pet => ({ ...pet, amount: 0 }))
  for (let i = 0; i < amount; i++) {
    const petIndex = await GetRandomPet(RadiantPetEgg)

    petList[petIndex].amount++
  }
  petList.reverse()
  // console.timeEnd('OpenRadiantPetEgg')
  // console.log(petList2)

  // let newList = Array(PetList).fill(0).map((pet, idx) => ({ ...PetList[idx], amount: 0}))
  // for (let i = 0; i < amount; i++) {
  //   let rng = +(Math.random() * 99.999).toFixed(4)
  //   newList[newList.findIndex(pet => pet.sum > rng)].amount += 1
  //   let pet = newList.find(pet => pet.sum > rng)
  //   pet.amount += 1
  // }
  // console.log(rngList)
  // let petList = rngList

  // let petList = [...PetList].map(pet => ({ ...pet, amount: 0 }))
  // let rngList = Array(amount).map(i => +(Math.random() * 99.999).toFixed(4))

  // for (let rng of rngList) {
  //   let pet = RadiantPetEgg.find(pet => pet.sum > rng)
  //   petList[petList.findIndex(p => p.name === pet.name)].amount += 1
  // }
  // console.log(petList)
  return petList
}

export async function OpenPetEgg(tier, amount) {
  let petList = [...PetList].map(pet => ({ ...pet, amount: 0 }))
  switch (tier) {
    case 1:
      for (let i = 0; i < amount; i++) {
        const petIndex = await GetRandomPet(RadiantPetEgg)

        petList[petIndex].amount++
      }
      petList.reverse()
      break
    default:
      break
  }

  return { data: petList }
}
