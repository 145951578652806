import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectInventory, selectTotalPets } from '../../petsSlice'
import PetBoxCell from './PetBoxCell'

function PetBox() {
  const inventory = useSelector(selectInventory)
  const total = useSelector(selectTotalPets)
  const [filter, setFilter] = useState(null)
  const pendingEggs = useSelector(state => state.pets.pendingEggs)

  const handleFilter = e => {
    // console.log(e.target.textContent)
    // setFilter(e.target.textContent)
    // Toggle the filter
    setFilter(filter === e.target.textContent ? null : e.target.textContent)
  }

  return (
    <>
      <div
        className={`flex flex-col justify-center text-center transition-opacity duration-100 ${
          pendingEggs > 0 ? 'opacity-75' : ''
        }`}
      >
        <h3 className="text-center">Filters</h3>
        <div className="flex flex-row justify-around">
          <button
            onClick={handleFilter}
            className={`px-6 py-2 border border-gray-300 rounded-md dark:text-slate-50 text-slate-900 hover:bg-gray-400/25 ${
              filter === 'Normal' ? 'bg-gray-400/75' : ''
            }`}
          >
            Normal
          </button>
          <button
            onClick={handleFilter}
            className={`px-6 py-2 border border-green-300 rounded-md dark:text-slate-50 text-slate-900 hover:bg-green-400/25 ${
              filter === 'Fine' ? 'bg-green-400/75' : ''
            }`}
          >
            Fine
          </button>
          <button
            onClick={handleFilter}
            className={`px-6 py-2 border border-blue-300 rounded-md dark:text-slate-50 text-slate-900 hover:bg-blue-400/25 ${
              filter === 'Rare' ? 'bg-blue-400/75' : ''
            }`}
          >
            Rare
          </button>
          <button
            onClick={handleFilter}
            className={`px-6 py-2 border border-purple-300 rounded-md dark:text-slate-50 text-slate-900 hover:bg-purple-400/25 ${
              filter === 'Epic' ? 'bg-purple-400/75' : ''
            }`}
          >
            Epic
          </button>
          <button
            onClick={handleFilter}
            className={`px-6 py-2 border border-orange-300 rounded-md dark:text-slate-50 text-slate-900 hover:bg-orange-400/25 ${
              filter === 'Legendary' ? 'bg-orange-400/75' : ''
            }`}
          >
            Legendary
          </button>
        </div>
      </div>
      <div
        className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 flex-wrap px-4 mx-auto my-2 ${
          pendingEggs > 0 ? 'animate-pulse ' : ''
        }`}
      >
        {inventory &&
          inventory.map((pet, idx) => (
            <PetBoxCell
              isHidden={filter !== null ? pet.rarity !== filter : false}
              key={pet.name}
              pet={pet}
            />
          ))}
      </div>
    </>
  )
}

export default PetBox
