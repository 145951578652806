import React from 'react'
import './App.css'
import Pets from './features/pets/Pets'

function App() {
  return (
    <div className="flex flex-col m-auto justify-start prose prose-slate bg-slate-50 min-h-screen min-w-full dark:prose-invert dark:bg-slate-900">
      {/* <nav className="flex ">
        <div className="flex flex-col justify-center items-center">
          <a href="">Home</a>
        </div>
      </nav> */}
      <Pets />
    </div>
  )
}

export default App
