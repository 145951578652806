import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PetBox } from './components'
import PetEgg from './components/egg'
import { PetList, RadiantPetEgg } from './petConstants'
import petsSlice, {
  openEggsAsync,
  selectInventory,
  selectPetStatus,
  selectTotalPets,
  testWebWorker
} from './petsSlice'

function Pets() {
  let dispatch = useDispatch()
  let pets = useSelector(state => state.pets)
  const inventory = useSelector(selectInventory)
  const total = useSelector(selectTotalPets)
  const status = useSelector(selectPetStatus)
  const pending = useSelector(state => state.pets.pendingEggs)

  return (
    <div className={`w-full flex flex-col h-screen`}>
      <h1>Pets</h1>
      <div>
        <p>Loading: {status.toString()}</p>
        <p>Pending: {pending.toString()}</p>
      </div>
      <div className="flex flex-row flex-wrap gap-2">
        <PetEgg amount={10} />
        <PetEgg amount={10_000} />
        <PetEgg amount={100_000} />
        <PetEgg amount={1_000_000} />
      </div>
      <div>
        <p>
          Owned Pet Types: {inventory.filter(item => item.amount > 0).length}/{PetList.length}
        </p>
        <p>Total Pets: {total}</p>
      </div>
      <div className="lg:w-6/12 w-full h-5/6 overflow-y-scroll">
        <PetBox />
      </div>
    </div>
  )
}

export default Pets
