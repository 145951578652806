import { createAsyncThunk, createSlice, createEntityAdapter, createAction } from '@reduxjs/toolkit'
import { PetList } from './petConstants'
import { OpenPetEgg } from './petsAPI'

// const rngWorker = new Worker(new URL('./api/rng-worker.js', import.meta.url))
const PetWorker = new Worker(new URL('../../app/workers/pets/index.js', import.meta.url))
const PetWorker2 = new Worker(new URL('../../app/workers/pets/index.js', import.meta.url))
const PetWorker3 = new Worker(new URL('../../app/workers/pets/index.js', import.meta.url))
const PetWorker4 = new Worker(new URL('../../app/workers/pets/index.js', import.meta.url))
const PetWorker5 = new Worker(new URL('../../app/workers/pets/index.js', import.meta.url))

const createWorkerAction = type => {
  let action = createAction(`${type}`, payload => {
    return {
      payload: payload,
      meta: {
        WebWorker: true
      }
    }
  })

  action.pending = createAction(`${type}/pending`)
  action.fulfilled = createAction(`${type}/fulfilled`, payload => {
    return {
      payload: payload
    }
  })
  action.rejected = createAction(`${type}/rejected`, payload => {
    return {
      payload: payload
    }
  })

  return action
}

const initialState = {
  status: 'idle',
  isLoading: false,
  pendingEggs: 0,
  inventory: Array(PetList.length)
    .fill(0)
    .map((pet, idx) => PetList[idx])
    .map(pet => ({ ...pet, amount: 0 }))
    .reverse(),
  total: 0
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const openEggsAsync = createAsyncThunk(
  'pets/openEggsAsync',
  async ({ tier, amount }, { dispatch }) => {
    dispatch(setLoading(true))
    const response = await OpenPetEgg(tier, amount)
    dispatch(setLoading(false))
    // The value we return becomes the `fulfilled` action payload
    return response.data
  }
)
// console.log(openEggsAsync)

export const testWebWorker = createAsyncThunk(
  'pets/testWebWorker',
  async (payload, { dispatch }) => {
    let action = {
      type: 'fake',
      payload: {
        amount: 1_000_000,
        tier: 1
      }
    }
    // PetWorker.postMessage(action)
    // PetWorker2.postMessage(action)
    // PetWorker3.postMessage(action)
    // PetWorker4.postMessage(action)
    // PetWorker5.postMessage(action)
    // rngWorker.postMessage(payload)
    // rngWorker.onmessage = e => {
    //   dispatch(addToInventory(e.data))
    // }
  }
)

export const OpenEgg = createWorkerAction('pets/OpenEgg')

// console.log(OpenEgg)
// export const OpenEgg = createAction('pets/OpenEgg', payload => {
//   return {
//     payload: payload,
//     meta: {
//       WebWorker: true
//     }
//   }
// })

function isPendingAction(action) {
  return action.type.endsWith('/pending')
}

export const petsSlice = createSlice({
  name: 'pets',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    open: (state, action) => {},
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    addToInventory: (state, action) => {
      let inv = [...state.inventory]

      for (let i = 0; i < inv.length; i++) {
        inv[i].amount += action?.payload.data[i].amount
      }

      state.inventory = inv

      // Set the total
      state.total = inv.reduce((acc, pet) => acc + pet.amount, 0)
    }
    // OpenEgg: (state, action) => {
    //   console.log(action)
    // }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(OpenEgg, state => {
        state.isLoading = true
        state.pendingEggs++
      })
      .addCase(OpenEgg.fulfilled, (state, action) => {
        state.pendingEggs--
        let inv = [...state.inventory]

        for (let i = 0; i < inv.length; i++) {
          inv[i].amount += action?.payload[i].amount
        }

        state.inventory = inv

        // Set the total
        state.total = inv.reduce((acc, pet) => acc + pet.amount, 0)
        state.isLoading = false
      })
      .addCase(openEggsAsync.pending, state => {
        // state.status = 'loading'
      })
      .addCase(openEggsAsync.fulfilled, (state, action) => {
        // state.status = 'idle'

        let pets = action.payload

        // Update the count in the inventory for each pet found
        // console.time('Update Inventory')
        let inv = [...state.inventory]

        for (let i = 0; i < inv.length; i++) {
          inv[i].amount += pets[i].amount
        }

        // Update the count of each pet in the inventory using a for loop

        // let counts = Array(PetList).fill(0)

        // for (let i = 0; i < pets.length; i++) {
        //   // let idx = 0
        //   // for (let j = 0; j < inv.length; j++) {
        //   //   if (inv[j].name === pets[i]) {
        //   //     idx = j
        //   //     break
        //   //   }
        //   // }
        //   counts[inv.findIndex(p => p.name === pets[i])] += 1
        // }

        // for (let i = 0; i < inv.length; i++) {
        //   inv[i].amount += counts[i]
        // }

        state.inventory = inv
        // console.timeEnd('Update Inventory')

        state.total = state.inventory.reduce((acc, pet) => acc + pet.amount, 0)
      })
      .addCase(openEggsAsync.rejected, state => {
        state.pendingEggs--
      })
      .addMatcher(isPendingAction, (state, action) => {
        // state.isLoading = true
      })
  }
})

export const { open, setLoading, addToInventory } = petsSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectInventory = state => state.pets.inventory
export const selectTotalPets = state => state.pets.total
export const selectPetStatus = state => state.pets.isLoading

export default petsSlice.reducer
