import { useState } from 'react'
import { useSelector } from 'react-redux'

function PetBoxCell({ pet, isHidden }) {
  const [isSelected, select] = useState(false)
  const pendingEggs = useSelector(state => state.pets.pendingEggs)

  const handleClick = () => {
    if (pet.amount > 0) {
      select(!isSelected)
    }
  }

  return (
    <div
      onClick={handleClick}
      className={`flex flex-col justify-center shadow-sm items-center select-none border rounded-sm p-2 cursor-pointer hover:shadow-lg hover:brightness-125
      ${isHidden ? 'invisible absolute h-0 w-0' : ''}
      ${
        pet.rarity === 'Legendary'
          ? 'bg-orange-400/50 border border-orange-400'
          : pet.rarity === 'Epic'
          ? 'bg-purple-400/50 border border-purple-400'
          : pet.rarity === 'Rare'
          ? 'bg-blue-400/50 border border-blue-400'
          : pet.rarity === 'Fine'
          ? 'bg-green-400/50 border border-green-400'
          : 'bg-gray-400/50 border border-gray-400'
      }
      ${pet.amount === 0 ? 'opacity-50' : ''}
      ${isSelected ? 'ring shadow-md ring-offset-2 dark:ring-offset-gray-100 text-green-500' : ''}`}
    >
      <h3>{pet.name}</h3>
      <h3>{pet.amount}</h3>
    </div>
  )
}

export default PetBoxCell
